import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'

import TaskTable from '../components/TaskTable.vue'
import TaskDetail from '../components/TaskDetail.vue'
import TaskSimpleDetail from '../components/TaskSimpleDetail.vue'
import VinDetail from '../components/VinDetail.vue'


import LoginForm from '../components/LoginForm.vue'
import LogoutForm from '../components/LogoutForm.vue'

import AboutPage from '../components/AboutPage.vue'
import NotFoundView from '../views/NotFoundView.vue'


//debug
import TableShow from '../components/TableShow.vue'


const routes = [
  {
    path: '/',
    name: 'about',
    component: AboutPage
  },
  {
    path: '/task',
    name: 'task',
    component: TaskTable,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/task/:id',
    name: 'TaskDetail',
    component: TaskDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/task_simple/:id',
    name: 'TaskSimpleDetail',
    component: TaskSimpleDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vin/:serias',
    name: 'Vin',
    component: VinDetail,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: LoginForm
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutForm
  },

  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFoundView
  },
  // debug
  {
    path: '/vu',
    name: 'vu',
    component: TableShow
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.initialState.status.loggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
