<template>
  <v-container grid-list-md text-xs-center>
    <v-row no-gutters>
      <v-col cols="12">

        <v-card class="mx-auto px-2 py-2">

          <template v-slot:text>
            <v-row no-gutters>
              <v-col>
                <v-sheet class="ma-0 pa-0">
                  <v-btn :href="`/task_simple/new`">Новая задача</v-btn>
                  <v-btn @click="SyncTable" :flat="ShowAllTask">Все задачи</v-btn>
                </v-sheet>
              </v-col>
              <v-col cols="9">
                <v-sheet class="ma-0 pa-0">
                  
                </v-sheet>
              </v-col>
            </v-row>
          </template>

          <v-data-table-server v-model:items-per-page="itemsPerPage" :page="page" :headers="headers"
            :items-length="totalItems" :sort-by="[{ key: 'date', order: 'desc' }]" :items="serverItems"
            :loading="loading" :search="search" :hide-headers="isMobile" :custom-filter="customFilter"
            class="{mobile: isMobile}" item-value="name" @update:options="loadItems">
            <template v-slot:body="{ items }">
              <tr v-for="item in items" :key="item.id" class="v-data-table__tr">
                <template v-if="!isMobile">
                  <td class="v-data-table__td v-data-table-column--align-start">

                    <v-checkbox v-model="item.done" readonly hide-details></v-checkbox>

                  </td>
                  <td class="v-data-table__td v-data-table-column--align-start">{{ this.formatDate(item.date) }}</td>
                  <td class="v-data-table__td v-data-table-column--align-end">

                    <template v-if="item.tasktype == 'shipment'">
                      <v-btn :href="`/task/${item.id}`">{{ item.nomer }}</v-btn>
                    </template>
                    <template v-else-if="item.tasktype == 'task_simple'">
                      <v-btn :href="`/task_simple/${item.id}`">{{ item.nomer }}</v-btn>
                    </template>
                    <template v-else>

                    </template>

                  </td>
                  <td class="v-data-table__td v-data-table-column--align-end">

                    <template v-if="item.tasktype == 'shipment'">
                      <v-btn :href="`/vin/${item.vin.series}`">{{ item.vin.name }}</v-btn>
                    </template>
                    <template v-else-if="item.tasktype == 'task_simple'">
                      <v-btn :href="`/task_simple/${item.id}`">{{ item.name }}</v-btn>
                    </template>
                    <template v-else>

                    </template>


                  </td>
                  <td class="v-data-table__td v-data-table-column--align-end">{{ item.catalog.class }}</td>
                  <td class="v-data-table__td v-data-table-column--align-end">{{ item.user.name }}</td>
                </template>
                <template v-else>
                  <td>
                    <v-container class="pa-0 ma-0">
                      <v-row no-gutters>
                        <v-col>
                          <v-checkbox v-model="item.done" readonly hide-details></v-checkbox>
                        </v-col>
                        <v-col class="d-flex justify-center align-center ">
                          {{ this.formatDate(item.date) }}
                        </v-col>
                      </v-row>
                      <v-divider inset></v-divider>

                      <v-row no-gutters>
                        <v-col>

                          <template v-if="item.tasktype == 'shipment'">
                            <v-btn :href="`/task/${item.id}`">{{ item.nomer }}</v-btn>
                          </template>
                          <template v-else-if="item.tasktype == 'task_simple'">
                            <v-btn :href="`/task_simple/${item.id}`">{{ item.nomer }}</v-btn>
                          </template>
                          <template v-else>

                          </template>

                        </v-col>
                      </v-row>
                      <v-divider inset></v-divider>

                      <v-row no-gutters>
                        <v-col>

                          <template v-if="item.tasktype == 'shipment'">
                            <v-btn :href="`/vin/${item.vin.series}`">{{ item.vin.name }}</v-btn>
                          </template>
                          <template v-else-if="item.tasktype == 'task_simple'">
                            <v-btn :href="`/task_simple/${item.id}`">{{ item.name }}</v-btn>
                          </template>
                          <template v-else>

                          </template>

                        </v-col>
                      </v-row>
                      <v-divider inset></v-divider>

                      <v-row no-gutters>
                        <v-col> {{ item.catalog.class }} </v-col>
                      </v-row>
                      <v-divider inset></v-divider>

                      <v-row no-gutters>
                        <v-col> {{ item.user.name }}</v-col>
                      </v-row>


                    </v-container>

                  </td>
                </template>
              </tr>

            </template>

            <template v-slot:bottom>
              <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </div>
            </template>

          </v-data-table-server>

        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>



<script>

import OrdersService from "../services/orders.service";
import { GlobalFormatDate } from "../helper/date.helper"

export default {
  name: "TaskTable",
  data() {
    return {
      isMobile: true,
      ShowAllTask: false,
      SortByTable: undefined,
      page: 1,
      itemsPerPage: 10,
      totalItems: 0,
      headersALL: [
        { title: 'В', value: 'done' },
        { title: 'Дата', sortable: true, align: 'start', value: 'date' },
        { title: 'Номер', align: 'end', key: 'nomer' },
        { title: 'VIN', align: 'end', key: 'vin.name' },
        { title: 'VIN:класс', align: 'end', key: 'catalog.class' },
        { title: 'Исполнитель', sortable: false, align: 'end', key: 'user.name' },
      ],
      headersModily: [
        { title: 'Данные', sortable: false, align: 'start' },
      ],
      headers: [],
      search: '',
      serverItems: [],
      loading: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.headerSet(this.isMobile)
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true
      this.SortByTable = sortBy

      try {
        let result = await OrdersService.GetList(page, itemsPerPage, this.SortByTable, this.ShowAllTask)
        if (result) {
          this.serverItems = result.rows
          this.totalItems = result.count
          this.loading = false
        }
      } catch (error) {
        console.log(error)
      }

    },
    async SyncTable() {
      if (this.ShowAllTask)
        this.ShowAllTask = false
      else
        this.ShowAllTask = true

      this.loading = true
      try {
        let result = await OrdersService.GetList(this.page, this.itemsPerPage, this.SortByTable, this.ShowAllTask)
        if (result) {
          this.serverItems = result.rows
          this.totalItems = result.count
          this.loading = false
        }
      } catch (error) {
        console.log(error)
      }

    },

    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
        //      this.headers = this.headersModily
      } else {
        this.isMobile = false
        //      this.headers = this.headersALL
      }
    },
    formatDate(date) {
      return GlobalFormatDate(date)
    },
    headerSet(newValue) {
      if (newValue) {
        this.headers = this.headersModily
      } else {
        this.headers = this.headersALL
      }
    },

    customFilter(items, search, filter) {

      search = search.toString().toLowerCase()
      return items.filter(row => filter(row["type"], search));

    }


  },
  watch: {
    isMobile(newValue) {
      this.headerSet(newValue)
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
      //return this.totalItems
      //return Math.ceil(this.serverItems.length / this.itemsPerPage)
    },
  },
}
</script>

<style scoped>
.mobile {
  color: #333;
}

.flex-content {
  width: 100%;
}

.flex-item {}
</style>