import AuthService from '../services/auth.service';

import api from "../services/api";
//import TokenService from "../services/token.service";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth ={
    namespaced: true,
    state: {
        initialState
    },
    getters: {
    //    isLoggedIn: state => state.status.loggedIn
    },
    mutations: {
        loginSuccess(state, user) {
            state.initialState.status.loggedIn = true
            state.initialState.user = user
        },
        loginFailure(state) {
            state.initialState.status.loggedIn = false
            state.initialState.user = null
        },
        logout(state) {
            state.initialState.status.loggedIn = false
            state.initialState.user = null
        },
    },
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);

                    //const token = TokenService.getLocalAccessToken();
                    if (user) {
                       //axiosInstance.defaults.headers.common['Authorization'] = 'Bearer  ' + token
                       api.defaults.headers.common['Authorization'] = 'Bearer  ' + user.AccessToken
                    }

                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            delete api.defaults.headers.common['Authorization'];
            commit('logout');
        },
    },
    modules: {
    }
}
