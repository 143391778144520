<template>

  <div>
    <v-alert
        v-model="showAlertAuth"
        closable
        type="error"
    >
      Ошибка авторизации!!!
    </v-alert>
  </div>

  <v-container grid-list-xs align-center>

      <v-sheet class="pa-12" rounded>
        <v-card class="mx-auto px-2 py-2" max-width="600">

          <v-form ref="form" @submit.prevent="login" v-if="show">

            <v-text-field
                v-model="username"
                :rules="nameRules"
                :counter="30"
                label="Пользователь"
                required
            ></v-text-field>

            <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                label="Пароль"
                hint="Больше 3 символов"
                counter
                @click:append="showPassword = !showPassword"
            ></v-text-field>

            <v-btn color="success" class="mt-4" block type="submit">Login</v-btn>

            <v-btn color="error" class="mt-4" block @click="reset">
              Reset Form
            </v-btn>

          </v-form>
        </v-card>
      </v-sheet>

  </v-container>

</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      show: true,
      showAlertAuth: false,
      showPassword: false,
      rules: {
        required: value => !!value || 'Обязательный.',
        min: v => v.length >= 3 || 'Больше 3 символов',
      },
      nameRules: [
        value => {
          if (value) return true
          return 'Пользователь не заполнен.'
        },
        value => {
          if (value?.length >= 3) return true
          return 'Пользователь должен быть не меньше 3 букв.'
        },
      ],
    }
  },
  methods: {
    async login() {
      let resutl = await this.$refs.form.validate()
      if (resutl){
        if (resutl.valid){
          let username = this.username
          let password = this.password
          try {
            let resultStore = await this.$store.dispatch('auth/login', {username, password})
            if (resultStore)
              this.$router.push('/task')
          }
          catch{
            this.showAlertAuth = true
          }
        }
      }
      
    },
    reset() {
      this.$refs.form.reset()
      this.showAlertAuth = false
    },
  },
}
</script>

<style scoped>

</style>