<template>

  <v-container grid-list-md text-xs-center>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto px-2 py-2">


          <v-container class="px-2 py-2">

            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-sheet class="ma-1 pa-1"><label>Наименование:</label></v-sheet>
              </v-col>
              <v-col cols="12" sm="8">
                <v-sheet class="ma-0 pa-0">
                  <v-text-field v-model="VinData.name" readonly density="compact" variant="solo-filled"></v-text-field>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-sheet class="ma-1 pa-1"><label>Статус:</label></v-sheet>
              </v-col>
              <v-col cols="12" sm="8">
                <v-sheet class="ma-0 pa-0">
                  <v-text-field v-model="VinData.status" readonly density="compact"
                                variant="solo-filled"></v-text-field>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-sheet class="ma-1 pa-1"><label>Заказчик:</label></v-sheet>
              </v-col>
              <v-col cols="12" sm="8">
                <v-sheet class="ma-0 pa-0">
                  <v-text-field v-model="VinData.customer" readonly density="compact"
                                variant="solo-filled"></v-text-field>
                </v-sheet>
              </v-col>
            </v-row>

          </v-container>

          <v-container class="px-2 py-2">
            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-sheet class="ma-1 pa-1">

                  <v-container class="px-0 py-0">

                    <v-row no-gutters>
                      <v-col cols="12" sm="12">
                        <v-sheet class="ma-2 pa-2">
                          <label class="font-weight-bold">Технические характеристики:</label>
                        </v-sheet>
                      </v-col>
                    </v-row>

                    <template v-for="item in VinData.techCharacteristics" :key="item.key">
                      <v-row no-gutters>
                        <v-col cols="12" sm="4">
                          <v-sheet class="ma-0 pa-0"><label>{{ item.key }}:</label></v-sheet>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-sheet class="ma-0 pa-0">
                            <v-text-field readonly>{{ item.value }}</v-text-field>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>

                </v-sheet>
              </v-col>
              <v-col cols="12" sm="4">
                <v-sheet class="ma-1 pa-1">

                  <v-container class="px-0 py-0">

                    <v-row no-gutters>
                      <v-col cols="12" sm="12">
                        <v-sheet class="ma-2 pa-2">
                          <label class="font-weight-bold">Дополнительная опции:</label>
                        </v-sheet>
                      </v-col>
                    </v-row>

                    <template v-for="item in VinData.modelOptions" :key="item.key">
                      <v-row no-gutters>
                        <v-col cols="12" sm="4">
                          <v-sheet class="ma-0 pa-0"><label>{{ item.key }}:</label></v-sheet>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-sheet class="ma-0 pa-0">
                            <v-text-field readonly>{{ item.value }}</v-text-field>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>

                </v-sheet>
              </v-col>
              <v-col cols="12" sm="4">
                <v-sheet class="ma-1 pa-1">

                  <v-container class="px-0 py-0">

                    <v-row no-gutters>
                      <v-col cols="12" sm="12">
                        <v-sheet class="ma-2 pa-2">
                          <label class="font-weight-bold">Дополнительная информация:</label>
                        </v-sheet>
                      </v-col>
                    </v-row>

                    <template v-for="item in VinData.extendedInfo" :key="item.key">
                      <v-row no-gutters>
                        <v-col cols="12" sm="4">
                          <v-sheet class="ma-0 pa-0"><label>{{ item.key }}:</label></v-sheet>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-sheet class="ma-0 pa-0">
                            <v-text-field readonly>{{ item.value }}</v-text-field>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>

                </v-sheet>
              </v-col>

            </v-row>

          </v-container>

        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import api from "@/services/api";

export default {
  name: "VinDetail",
  data() {
    return {
      serias: this.$route.params.serias,
      VinData: {},
    }
  },
  mounted() {
    api.get("/pwa/vin?vin=" + this.serias)
        .then(response => {
          this.VinData = response.data [0]
        })
        .catch(error => console.log(error))

  },
  methods: {
    shipped: function () {
      console.log("shipped")
    }
  },
}
</script>

<style scoped>

</style>