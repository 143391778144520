<template>
  <v-app>

    <v-toolbar class="align-center">
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <div class="link-container d-flex justify-center align-center">
        <span v-if="isLoggedIn">
          <router-link to="/task">
            <v-btn class="mx-4 white--text" elevation="2" x-large rounded color="green darken-1">Task</v-btn>
          </router-link>
          <router-link to="/Logout">
            <v-btn class="mx-4 white--text" elevation="2" x-large rounded color="green darken-1">Logout</v-btn>
          </router-link>
           </span> <span v-else>
          <router-link to="/login">
            <v-btn class="mx-4 white--text" elevation="2" x-large rounded color="green darken-1">Login</v-btn>
          </router-link>
          </span>
        <router-link to="/about">
          <v-btn class="mx-4 white--text" elevation="2" x-large rounded color="green darken-1">About</v-btn>
        </router-link>
      </div>

    </v-toolbar>

    <!--<v-main class="d-flex align-center justify-center" style="min-height: 300px;"> -->
    <v-main>
      <router-view/>
    </v-main>

  </v-app>

</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.state.auth.initialState.status.loggedIn
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout')
          .then(() => {
            this.$router.push('/login')
          })
    }
  },
}
</script>
