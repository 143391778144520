import api from "./api";
import authHeader from "./api.auth.header.js";
import CatchTokenError from "./broken.auth.service.js";


class UserService {
    Get(name) {
        return api
            .get("/pwa/users" + "?name=" + name, { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return []
            })
            .catch(error => {
                CatchTokenError(error)
            })
    }

}

export default new UserService()