<template>

  <div>
    <v-alert v-model="AlertData.showAlertUser" closable type="error">{{ AlertData.msgAlertUser }}</v-alert>
    <v-alert v-model="AlertData.showMesUser" closable type="success">{{ AlertData.msgMesUser }}</v-alert>
  </div>

  <v-container grid-list-md text-xs-center>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto px-2 py-2">

          <v-form ref="form" @submit.prevent="ShippedSendForm">

            <v-container class="px-2 py-2">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Номер:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-text-field v-model="DocNomer" readonly density="compact" variant="solo-filled"></v-text-field>
                  </v-sheet>
                </v-col>
              </v-row>


              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Дата:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-text-field :value="computedDateFormatted" readonly density="compact"
                      variant="solo-filled"></v-text-field>
                  </v-sheet>
                </v-col>
              </v-row>


              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Наименование:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-text-field v-model="DocName" readonly density="compact" variant="solo-filled"></v-text-field>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>VIN:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-btn density="compact" :href="`/vin/${DocVIN.series}`">{{ DocVIN.name }}</v-btn>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Комплектация проверена:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">

                  <v-row no-gutters>
                    <v-col>
                      <v-sheet class="ma-0 pa-0">

                        <template v-if="DocEquipmentCheckReadOnly">
                          <v-checkbox v-model="DocEquipmentCheck" readonly density="compact"></v-checkbox>
                        </template>
                        <template v-else>
                          <v-btn color="success"  variant="flat" @click="DocEquipmentCheckClick">Комплектация проверена</v-btn>
                        </template>

                      </v-sheet>
                    </v-col>
                    <v-col cols="9">
                      <v-sheet class="ma-0 pa-0">
                        <v-text-field :value="computedEquipmentDateFormatted" readonly density="compact"
                          variant="solo-filled"></v-text-field>
                      </v-sheet>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Выполнена:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-checkbox v-model="DocDone" density="compact"></v-checkbox>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Исполнитель:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-text-field v-model="user.name" type="search" list="UsersList" placeholder="Выберите исполнителя"
                      density="compact" variant="solo-filled" @keyup="sendSearch"
                      @update:modelValue="getUpdatedUser"></v-text-field>

                    <datalist id="UsersList">
                      <option v-for="u in users" :key="u.id">{{ u.name }}</option>
                    </datalist>

                  </v-sheet>
                </v-col>
              </v-row>

              <template v-if="DocEquipmentCheckReadOnly">
                <v-btn color="success" type="submit" variant="flat">Отгружен</v-btn>
              </template>
              <template v-else>
                <v-btn color="success" type="submit" variant="flat" disabled>Отгружен</v-btn>
              </template>


              
              <v-btn color="success" variant="text" @click="ChangleUser">Сменить Исполнителя</v-btn>

            </v-container>
          </v-form>

        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import OrdersService from "../services/orders.service"
import UserService from "../services/user.service"
import { GlobalFormatDate } from "../helper/date.helper"


export default {
  name: "TaskDetail",
  data() {
    return {
      id: this.$route.params.id,
      DocNomer: "",
      DocDate: new Date(),
      DocName: "",
      user: { id: null, name: '' },
      selectedUser: null,
      DocVIN: "",
      DocDone: false,

      DocEquipmentCheck: false,
      DocEquipmentCheckDate: new Date(),
      DocEquipmentCheckReadOnly: false,

      users: [
        { id: 0, name: 'Пожалуйста, исполнителя' }
        // 'Пожалуйста, исполнителя',
        //  {value: null, text: 'Пожалуйста, исполнителя'}
      ],
      AlertData: {
        showAlertUser: false,
        msgAlertUser: "",
        showMesUser: false,
        msgMesUser: "",
      }
    }
  },
  mounted() {
    OrdersService.GetTask(this.id).then(data => {
     // console.log(data)
      this.DocNomer = data.nomer
      this.DocDate = data.date
      this.DocName = data.name
      this.user = data.user
      this.selectedUser = data.user
      this.DocVIN = data.vin
      this.DocDone = data.done
      this.DocEquipmentCheck = data.equipment_check
      this.DocEquipmentCheckDate = data.equipment_check_date

      if(this.DocEquipmentCheck)
        this.DocEquipmentCheckReadOnly = true

    })

  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.DocDate)
    },
    computedEquipmentDateFormatted() {
      return this.formatDate(this.DocEquipmentCheckDate)
    },
  },

  methods: {
    async ShippedSendForm() {
      const putOnject = {
        id: this.id,
        done: this.DocDone,
        user: this.user
      }
      try {
            let result = await OrdersService.PutTask(this.id, putOnject)
            if (result){
              this.DocDone = result.done
              this.AlertData.msgMesUser = result.msg
              this.AlertData.showMesUser = true
            }else{
              //console.log(err)
              this.AlertData.msgAlertUser = "Ошибка изменения выполнения!"
              this.AlertData.showAlertUser = true       
            }
          }
          catch{
            //console.log(err)
            this.AlertData.msgAlertUser = "Ошибка изменения выполнения!"
            this.AlertData.showAlertUser = true  
          }
    },
    async DocEquipmentCheckClick(){
      const putOnject = {
        id: this.id,
        equipment_check: true
      }
      try {
            let result = await OrdersService.PutTask(this.id, putOnject)
            if (result){
              this.AlertData.msgMesUser = result.msg
              this.AlertData.showMesUser = true

              this.DocEquipmentCheck = result.equipment_check
              this.DocEquipmentCheckDate = result.equipment_check_date
              this.DocEquipmentCheckReadOnly = true

            }else{
              //console.log(err)
              this.AlertData.msgAlertUser = "Ошибка изменения выполнения!"
              this.AlertData.showAlertUser = true       
            }
          }
          catch{
            //console.log(err)
            this.AlertData.msgAlertUser = "Ошибка изменения выполнения!"
            this.AlertData.showAlertUser = true  
          }
      



    },
    formatDate(date) {
      return GlobalFormatDate(date)
    },
    ChangleUser: function () {
      const putOnject = {
        id: this.id,
        user: this.selectedUser,
      }
      OrdersService.PutTask(this.id, putOnject)
        .then(data => {
          this.AlertData.msgMesUser = data.msg
          this.AlertData.showMesUser = true
        }).catch(err => {
          console.log(err)
          this.AlertData.msgAlertUser = "Ошибка изменения исполнителя!"
          this.AlertData.showAlertUser = true
        })

    },
    getUpdatedUser() {
      const UserFind = this.users.find(el => el.name === this.user.name)
      if (UserFind === undefined) {
        return
      }
      this.selectedUser = UserFind
    },
    async sendSearch() {
      if (this.user.name.length > 3) {
        try {
          let result = await UserService.Get(this.user.name)
          if (result) {
            this.users = result
          }
        }
        catch {
          this.user = { id: null, name: '' }
        }
      }
    }
  },
}

</script>

<style scoped></style>