import TokenService from "./token.service";

export default function authHeader() {
    let user = TokenService.getUser() 
  
    let header = {
      "Content-Type": "application/json",
      "xxx-gr_task_shipmet": "4ZebBJXMp9plqwPe0W9et",
    }

    if (user && user.AccessToken) {
      header['Authorization'] = 'Bearer ' + user.AccessToken
    } 
    return header
  }
