function GlobalFormatDate(date) {
  let month = '01'
  let day = '01'
  let year = '2000'   

  if (date !== "0001-01-01T00:00:00") {
 
    const d = new Date(date)
    month = '' + (d.getMonth() + 1)
    day = '' + d.getDate()
    year = d.getFullYear()

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

  }
  return [day, month, year].join('.');
}

export { GlobalFormatDate }