<template>
  <h1 align="center">Выход</h1>
</template>

<script>
export default {
  name: "LogoutForm",
  mounted() {
    this.$store.dispatch('auth/logout')
        .then(() => this.$router.push('/login'))
  },
}
</script>

<style scoped>

</style>