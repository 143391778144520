<template>

  <div>
    <v-alert v-model="AlertData.showAlertUser" closable type="error">{{ AlertData.msgAlertUser }}</v-alert>
    <v-alert v-model="AlertData.showMesUser" closable type="success">{{ AlertData.msgMesUser }}</v-alert>
  </div>

  <v-container grid-list-md text-xs-center>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto px-2 py-2">

          <v-form ref="form" @submit.prevent="shipped">

            <v-container class="px-2 py-2">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Номер:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-text-field v-model="DocNomer" readonly density="compact" variant="solo-filled"></v-text-field>
                  </v-sheet>
                </v-col>
              </v-row>


              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Дата:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <template v-if="TaskNew">

                      <v-menu v-model="DataPickerMenu" :close-on-content-click="false">
                        <template v-slot:activator="{ props }">
                          <v-text-field :value="computedDateFormatted" v-bind="props"></v-text-field>
                        </template>
                        <v-date-picker v-model="DocDate" first-day-of-week="1" locale="ru" title="Дата задачи"
                          scrollable show-adjacent-months @update:modelValue="DataPickerChangle"> </v-date-picker>
                      </v-menu>

                    </template>
                    <template v-else>
                      <v-text-field :value="computedDateFormatted" readonly density="compact"
                        variant="solo-filled"></v-text-field>
                    </template>
                  </v-sheet>
                </v-col>
              </v-row>


              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Наименование:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <template v-if="TaskNew">
                      <v-text-field v-model="DocName" :rules="nameRules" required density="compact"
                        variant="solo-filled"></v-text-field>
                    </template>
                    <template v-else>
                      <v-text-field v-model="DocName" readonly density="compact" variant="solo-filled"></v-text-field>
                    </template>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Выполнена:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">

                    <template v-if="TaskNew">
                      <v-checkbox v-model="DocDone" readonly density="compact"></v-checkbox>
                    </template>
                    <template v-else>
                      <v-checkbox v-model="DocDone" density="compact"></v-checkbox>
                    </template>

                  </v-sheet>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-sheet class="ma-1 pa-1"><label>Исполнитель:</label></v-sheet>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-sheet class="ma-0 pa-0">
                    <v-text-field v-model="user.name" type="search" list="UsersList" placeholder="Выберите исполнителя"
                      density="compact" variant="solo-filled" @keyup="sendSearch"
                      @update:modelValue="getUpdatedUser"></v-text-field>

                    <datalist id="UsersList">
                      <option v-for="u in users" :key="u.id">{{ u.name }}</option>
                    </datalist>

                  </v-sheet>
                </v-col>
              </v-row>


              <v-btn color="success" type="submit" variant="flat">Сохранить</v-btn>
              <template v-if="!TaskNew">
                <v-btn color="success" variant="text" @click="ChangleUser">Сменить Исполнителя</v-btn>
              </template>

            </v-container>
          </v-form>

        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import OrdersService from "../services/orders.service"
import UserService from "../services/user.service"

import { GlobalFormatDate } from "../helper/date.helper"


export default {
  name: "TaskSimpleDetail",
  data() {
    return {
      TaskNew: false,
      id: this.$route.params.id,
      DocNomer: "",
      DocDate: new Date(),
      DataPickerMenu: false,
      DocName: "",
      user: { id: null, name: '' },
      selectedUser: undefined,
      DocDone: false,
      users: [
        { id: 0, name: 'Пожалуйста, исполнителя' }
        // 'Пожалуйста, исполнителя',
        //  {value: null, text: 'Пожалуйста, исполнителя'}
      ],
      AlertData: {
        showAlertUser: false,
        msgAlertUser: "",
        showMesUser: false,
        msgMesUser: "",
      },
      nameRules: [
        value => {
          if (value) return true
          return 'Наименование не заполнен.'
        },
        value => {
          if (value?.length >= 3) return true
          return 'Наименование должен быть не меньше 3 букв.'
        },
      ],
    }
  },
  mounted() {
    if (this.id === 'new') {
      this.TaskNew = true
      this.DocDate = new Date()
    } else {
      this.TaskNew = false
      OrdersService.GetTask(this.id).then(data => {
        this.DocNomer = data.nomer
        this.DocDate = data.date
        this.DocName = data.name
        this.user = data.user
        this.selectedUser = data.user
        this.DocVIN = data.vin
        this.DocDone = data.done
      })
    }
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.DocDate)
    },
  },

  methods: {
    DataPickerChangle(val) {
      this.DocDate = val
      this.DataPickerMenu = false
    },

    shipped() {
      if (this.TaskNew) {

        let PostObject = {
          done: this.DocDone,
          date: this.DocDate,
          name: this.DocName,
          user: this.selectedUser
        }
        OrdersService.PostSimpleTask(PostObject)
          .then(data => {
            this.AlertData.msgMesUser = data.msg
            this.AlertData.showMesUser = true
            this.DocNomer = data.nomer
            this.id = data.id
            this.TaskNew = false
            this.$router.push({ path: `/task_simple/${data.id}` })
          }).catch(err => {
            console.log(err)
            this.AlertData.msgAlertUser = "Ошибка изменения выполнения!"
            this.AlertData.showAlertUser = true
          })

      } else {
        const putOnject = {
          id: this.id,
          done: this.DocDone,
        }
        OrdersService.PutSimpleTask(this.id, putOnject)
          .then(data => {
            this.AlertData.msgMesUser = data.msg
            this.AlertData.showMesUser = true
          }).catch(err => {
            console.log(err)
            this.AlertData.msgAlertUser = "Ошибка изменения выполнения!"
            this.AlertData.showAlertUser = true
          })
      }
    },
    formatDate(date) {
      return GlobalFormatDate(date)
    },
    ChangleUser: function () {
      const putOnject = {
        id: this.id,
        user: this.selectedUser,
      }
      OrdersService.PutTask(this.id, putOnject)
        .then(data => {
          this.AlertData.msgMesUser = data.msg
          this.AlertData.showMesUser = true
        }).catch(err => {
          console.log(err)
          this.AlertData.msgAlertUser = "Ошибка изменения исполнителя!"
          this.AlertData.showAlertUser = true
        })

    },
    getUpdatedUser() {
      if (this.users) {
        const UserFind = this.users.find(el => el.name === this.user.name)
        if (UserFind === undefined) {
          return
        }
        this.selectedUser = UserFind
      }
    },
    async sendSearch() {
      if (this.user.name.length > 3) {
        try {
          let result = await UserService.Get(this.user.name)
          if (result) {
            this.users = result
          }
        }
        catch {
          this.user = { id: null, name: '' }
        }
      }
    }
  }
}
</script>

<style scoped></style>