import api from "./api";
import authHeader  from "./api.auth.header.js";
import CatchTokenError  from "./broken.auth.service.js";


class OrdersService {
    GetList(page, itemsPerPage, sortBy, ShowAllTask) {

        let url = "?start=" + page + "&count=" + itemsPerPage + "&alltask=" + ShowAllTask

        if (sortBy[0]) {
            url = url + "&sort=" + sortBy[0].key
            url = url + "&order=" + sortBy[0].order
        }

        return api
            .get("/pwa/shipment" + url, { headers: authHeader() } )
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return { rows: [], count: 0 }
            })
            .catch(error => {
                CatchTokenError(error)
                return { rows: [], count: 0 }
            })
    }

    GetTask(id) {
        return api
            .get("/pwa/shipment" + "?id=" + id, { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return []
            })
            .catch(error => {
                CatchTokenError(error)
            })

    }
    PutTask(id, putOnject) {
        return api
            .put("/pwa/shipment" + "?id=" + id, putOnject, { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return []
            })
            .catch(error => CatchTokenError(error))

    }


    GetSimpleTask(id) {
        return api
            .get("/pwa/task_simple" + "?id=" + id, { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return []
            })
            .catch(error => CatchTokenError(error))
    }
    PutSimpleTask(id, putOnject) {
        return api
            .put("/pwa/task_simple" + "?id=" + id,  putOnject, { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return []
            })
            .catch(error => CatchTokenError(error))

    }
    PostSimpleTask(putOnject) {
        return api
            .post("/pwa/task_simple", putOnject, { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    return response.data
                }
                return []
            })
            .catch(error => CatchTokenError(error))

    }


}

export default new OrdersService()