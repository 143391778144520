import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { ru, en } from 'vuetify/locale'


// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: { en, ru },
  },
})
