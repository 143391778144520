import store from '../store/index'
import router from '../router/index'

export default function CatchTokenError(error) {
    if (error.response.status === 402) {
        store.dispatch('auth/logout')
            .then(() => router.push('/login'))
    } else {
        console.log(error)
    }
  }