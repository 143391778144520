<template>
    <h1 align="center">О программе</h1>
</template>

<script>
export default {
name: "AboutPage"
}
</script>

<style scoped>

</style>