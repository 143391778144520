import api from "./api";
import TokenService from "./token.service";
import { Base64 } from 'js-base64';

class AuthService {
    login({ username, password }) {
        //api.defaults.headers.post['Authorization'] = 'Basic ' + Base64.encode(username + ":" + password)
        return api
            .post("/auth/login", "", { headers: {
                    "Authorization": 'Basic ' + Base64.encode(username + ":" + password),
                }},
                )
            .then((response) => {
                if (response.data.AccessToken) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }
    
}

export default new AuthService();